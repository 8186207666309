import Vue from 'vue/dist/vue.js';
import VisitSteps from './VisitSteps';
import VisitFirstStep from './VisitFirstStep';
import VisitTwoStep from './VisitTwoStep';
import VisitThreeStep from './VisitThreeStep';
import VisitFourStep from './VisitFourStep';
import VisitFiveStep from './VisitFiveStep';
import VisitThanksStep from './VisitThanksStep';
import VisitClose from './VisitClose';
import EventBus from './../event-bus';
import axios from 'axios';
import $ from 'jquery';

export default Vue.component('visit-app', {
  props: [
    'url_get_visit_post',
    'url_set_visit_post',
    'url_get_form_visit_post'
  ],
  components: {
    VisitSteps, 
    VisitClose,
    VisitFirstStep,
    VisitTwoStep,
    VisitThreeStep,
    VisitFourStep,
    VisitFiveStep,
    VisitThanksStep
  },
  data() {
    return {
      current_step: 1,
      salon_id: null,
      salon: {},
      type_space: null,
      visit: null,
      type_service: null,
      selected_date: null,
      url: new URLSearchParams(window.location.search),
      
      visit_data: [
        'customer_fname',
        'customer_lname',
        'customer_email',
        'customer_phone',
      ],
    };
  },
  mounted() {
    EventBus.$on('SET_STEP', (payLoad) => {
      this.scrollTop();
      if(this.current_step > payLoad){
        switch (payLoad){
          case 1:
            this.url.delete('salon_id');
            this.url.delete('type_space');
            this.url.delete('type_service');
            break;
          case 2:
            this.url.delete('type_space');
            this.url.delete('type_service');
            break;
          case 3:
            this.url.delete('type_service');
            break;
        }
        this.updateUrl();
      }
      this.current_step = payLoad;
    });
    EventBus.$on('SET_SALON_ID', (payLoad) => {
      this.salon_id = payLoad;
      if(payLoad !== '0'){
        this.url.set('salon_id', payLoad);
        this.updateUrl();
      }
      this.getVisits(payLoad).then(response => {
        this.salon = response.data;
      }).catch(err => {
        console.error(err);
      });
    });
    EventBus.$on('SET_TYPE_SPACE', (payLoad) => {
      this.type_space = payLoad;
      this.url.set('type_space', payLoad);
      this.updateUrl();
    });
    EventBus.$on('SET_TYPE_SERVICE', (payLoad) => {
      this.type_service = payLoad;
      this.url.set('type_service', payLoad);
      this.updateUrl();
    });
    EventBus.$on('SET_CLIENT_DATA', (payLoad) => {
      this.visit_data[payLoad['field']] = payLoad['value'];
      this.url.set('client_data', payLoad);
      this.updateUrl();
    });
    EventBus.$on('SET_VISIT', (payLoad) => {
      this.visit = payLoad;
    });
    this.checkState();
  },
  methods: {
    updateUrl: function (){
      window.history.pushState(null, null, this.url.toString() ? `?${this.url}` : '');
    },
    checkState: async function () {
      this.scrollTop();
      if (this.url.get('salon_id')) {
        this.salon_id = this.url.get('salon_id');
        this.current_step = 2;
        try {
          const response = await this.getVisits(this.salon_id);
          this.salon = response.data;
        } catch (err){
          console.error(err);
        }
      }
      if (this.url.get('type_space')) {
        this.type_space = this.url.get('type_space');
        this.current_step = 3;
      }
      if (this.url.get('type_service')) {
        this.type_service = this.url.get('type_service');
        this.current_step = 4;
      }
    },
    getVisits: function(salonId) {
      return axios.post(this.url_get_visit_post, {
        salonId: salonId
      });
    },
    scrollTop() {
      document.querySelector('.visit-app').scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    }
  }
});
