<template>
  <div class="shops__inner">
    <div class="shops__form" v-show="!isLoading && showSearch">
      <div class="shops__row shops__vleft">
        <div class="shops__col shops--mb40">
          <div class="shops__text" v-html="translate.title"></div>
<!--          <div class="form__wrapper shops&#45;&#45;max260">-->
<!--            <form v-on:submit.prevent="searchCity()">-->
<!--              <label for="city" class="form__label">{{translate.insert_city}}</label>-->
<!--              <input-->
<!--                  type="text"-->
<!--                  class="form__input"-->
<!--                  id="city"-->
<!--                  v-model="search"-->
<!--                  autocomplete="off">-->
<!--            </form>-->
<!--          </div>-->
        </div>
<!--        <div class="shops__col shops&#45;&#45;mb23">-->
<!--          <div class="shops&#45;&#45;max260">-->
<!--            <button type="button" class="button button&#45;&#45;orange button&#45;&#45;small button&#45;&#45;w100" @click="searchCity()">-->
<!--              {{translate.search_shops}}-->
<!--            </button>-->
<!--          </div>-->
<!--        </div>-->
        <div class="shops__col">
          <div class="shops--max260">
            <button type="button" class="button button--blue button--small button--w100" @click="searchCityLocation()">
              {{translate.search_shops_location}}
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="shops__map" v-show="!isLoading">
      <gmap-map :center="center"
                :zoom="zoom"
                :options="{styles: styles}"
                style="width: 100%; height: 550px"
                ref="gmap">
        <gmap-marker :key="i" v-for="(m,i) in listLocal"
                     :position="m.marker.position"
                     :clickable="true"
                     :icon="'/wp-content/themes/flexible-theme/assets/img/marker.png'"
                     @click="toggleInfoWindow(m.marker,i)"></gmap-marker>
      </gmap-map>
      <div class="shops__modal" v-if="infoContentShow">

        <div @click="closeModal()" class="visit-app__close"></div>
        <div class="shops__modalWrapper" >
          <div class="shops__modalTitle">
            {{ infoContent.title }}
          </div>
          <div class="shops__modalAddress" v-if="infoContent.address" v-html="infoContent.address.address"></div>
          <div class="shops__modalHoursWork" v-if="infoContent.address" v-html="infoContent.address.worktime"></div>
          <div class="shops__modalButton shops__modalButtonCenter">
            <a :href="infoContent.url" class="button button--orange">
              {{translate.meeting}}
            </a>
          </div>
          <div class="shops__modalButton shops__modalButtonCenter">
            <a :href="infoContent.route" class="button button--orange" target="_blank" v-if="infoContent.route">
              {{translate.mapping_route}}
            </a>
          </div>
          <div class="shops__modalButton shops__modalButtonCenter">
            <a :href="infoContent.salon_url.url" class="visit-app__step-2--link shops__modalButtonCenter">
              {{translate.more}}
              <svg xmlns="http://www.w3.org/2000/svg" width="43.196" height="42.243" viewBox="0 0 43.196 42.243"><g id="Group_34" data-name="Group 34" transform="translate(-4400 1148.339) rotate(-90)"><g id="Group_33" data-name="Group 33" transform="translate(1108.218 4420.016) rotate(-45)"><path id="Path_29" data-name="Path 29" d="M0,0V26.781H26.959" fill="none" stroke="" stroke-width="6"></path></g> <path id="Path_50" data-name="Path 50" d="M982.609,2945.953V2907" transform="translate(144.609 1493)" fill="none" stroke="" stroke-width="6"></path></g></svg>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {gmapApi} from 'vue2-google-maps';
  import axios from 'axios';

  export default {
    props: {
      translate: {
        required: true
      },
      shopsLink: {
        required: true
      },
      basicZoom: {
        required: true
      },
      showSearch: {
        required: true
      }
    },
    components: {
      gmapApi
    },
    computed: {
      google: gmapApi
    },
    data: () => ({
      isLoading: true,
      search: null,
      center: {
        lat: 52.0591869,
        lng: 19.5021993
      },
      styles: [
        {
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#f5f5f5"
            }
          ]
        },
        {
          "elementType": "labels.icon",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        },
        {
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#616161"
            }
          ]
        },
        {
          "elementType": "labels.text.stroke",
          "stylers": [
            {
              "color": "#000"
            }
          ]
        },
        {
          "featureType": "administrative",
          "elementType": "geometry.fill",
          "stylers": [
            {
              "color": "#000000"
            }
          ]
        },
        {
          "featureType": "administrative",
          "elementType": "geometry.stroke",
          "stylers": [
            {
              "color": "#000000"
            },
            {
              "lightness": 17
            },
            {
              "weight": 1.2
            }
          ]
        },
        {
          "featureType": "administrative.land_parcel",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#fefefe"
            },
            {
              "lightness": 20
            }
          ]
        },
        {
          "featureType": "poi",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#eeeeee"
            }
          ]
        },
        {
          "featureType": "poi",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#757575"
            }
          ]
        },
        {
          "featureType": "poi.park",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#e5e5e5"
            }
          ]
        },
        {
          "featureType": "poi.park",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#9e9e9e"
            }
          ]
        },
        {
          "featureType": "road",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#ffffff"
            }
          ]
        },
        {
          "featureType": "road.arterial",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#757575"
            }
          ]
        },
        {
          "featureType": "road.highway",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#dadada"
            }
          ]
        },
        {
          "featureType": "road.highway",
          "elementType": "geometry.stroke",
          "stylers": [
            {
              "visibility": "on"
            }
          ]
        },
        {
          "featureType": "road.highway",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#616161"
            }
          ]
        },
        {
          "featureType": "road.local",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#9e9e9e"
            }
          ]
        },
        {
          "featureType": "transit.line",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#e5e5e5"
            }
          ]
        },
        {
          "featureType": "transit.station",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#eeeeee"
            }
          ]
        },
        {
          "featureType": "water",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#c9c9c9"
            }
          ]
        },
        {
          "featureType": "water",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#9e9e9e"
            }
          ]
        }
      ],
      zoom: 0,
      infoContent: {},
      infoWindowPos: null,
      infoWinOpen: false,
      infoContentShow: false,
      currentMidx: null,
      isMobile: false,
      infoOptions: {
        pixelOffset: {
          width: 0,
          height: -35
        }
      },
      listBasic: [],
      listLocal: [],
    }),
    mounted() {
      this.setEvents();
      this.detectMobile();
      this.setZoom();


      this.getItems();

      document.querySelector('.shops').addEventListener('click', () => {

        if (!this.infoContentShow) return;
        this.closeModal();
      });
    },
    methods: {
      setEvents() {
        window.addEventListener('resize', () => {
          this.detectMobile();
        })
      },
      detectMobile() {
        this.isMobile = ( window.innerWidth <= 768 );
      },
      setZoom() {
        if (!this.isMobile) {
          this.zoom = this.basicZoom;
        } else {
          this.zoom = 5.5
        }

        console.log(this.zoom);
      },
      toggleInfoWindow(marker, idx) {
        this.infoWindowPos = marker.position;
        this.infoContent = marker.infoText;

        setTimeout(() => {
          this.infoContentShow = true;
        });
      },
      async searchCityLocation() {
        // this.isLoading = true;
        const permissionStatus = await navigator?.permissions?.query({name: 'geolocation'})
        const hasPermission = permissionStatus?.state

        if (hasPermission == 'granted') {
          this.$getLocation({
            enableHighAccuracy: false,
            timeout: Infinity,
            maximumAge: 0
          })
              .then(coordinates => {
                this.listLocal = this.listBasic;

                this.center = {
                  lat: coordinates.lat,
                  lng: coordinates.lng
                };

                this.zoom = 12;
                // this.isLoading = false;
              });
        } else {
          alert(this.translate.location_denied);
        }
      },
      searchCity() {

        // this.$gmapApiPromiseLazy().then(() => {
        //   const geocoder = new google.maps.Geocoder();
        //   const address = this.search;
        //
        //   geocoder.geocode( { 'address': address }, function(results, status) {
        //
        //     if (status == google.maps.GeocoderStatus.OK) {
        //       const latitude = results[0].geometry.location.lat();
        //       const longitude = results[0].geometry.location.lng();
        //
        //       console.log('longitude ' + latitude)
        //       console.log('longitude ' + longitude)
        //       // initialize(latitude,longitude);
        //     }
        //   });
        // })

        this.listLocal = this.listBasic.filter((item) => {
          // const rex = new RegExp(this.search.toLowerCase(), 'gm');
          // return item.city.toLowerCase().match(rex);

          const dict = {"ą":"a", "ć":"c", "ś":"s", "ż":"z", "ź":"z", "ń":"n", "ó":"o", "ę":"e"}
          this.search.replace(/[^\w ]/g, function(char) {
            return dict[char] || char;
          });

          return this.leanUpSpecialChars(item.city.toLowerCase()).indexOf(this.leanUpSpecialChars(this.search.toLowerCase())) != -1;
        });

        if (this.listLocal.length == 1 ) {
        this.center = {
          lat: this.listLocal[0].marker.position.lat,
          lng: this.listLocal[0].marker.position.lng
        };

        this.$emit('change-location', {
          city: this.search,
          lat: this.listLocal[0].marker.position.lat,
          lng: this.listLocal[0].marker.position.lng
        })

        this.zoom = 12;

        } else {
          this.listLocal = this.listBasic;
        }

        // } else {
        //
        //   this.center = {
        //     lat: this.center.lat,
        //     lng: this.center.lng
        //   };
        //
        //   this.zoom = 6.35;
        // }
      },
      leanUpSpecialChars(str) {
        return str
          .replace(/[Ą]/g,"A")
          .replace(/[ą]/g,"a")
          .replace(/[Ć]/g,"C")
          .replace(/[ć]/g,"c")
          .replace(/[Ś]/g,"S")
          .replace(/[ś]/g,"s")
          .replace(/[Ż]/g,"z")
          .replace(/[ż]/g,"z")
          .replace(/[Ź]/g,"Z")
          .replace(/[ź]/g,"z")
          .replace(/[Ń]/g,"N")
          .replace(/[ń]/g,"n")
          .replace(/[Ó]/g,"O")
          .replace(/[ó]/g,"o")
          .replace(/[Ę]/g,"E")
          .replace(/[ę]/g,"e")
          .replace(/[^a-z0-9]/gi,'');
      },
      getItems() {
        this.isLoading = true;
        axios.get(this.shopsLink)
          .then((response) => {
            this.listBasic = response.data;
            this.listLocal = response.data;
            this.isLoading = false;
          });
      },
      closeModal() {
        this.infoContentShow = false;
      }
    },
    events: {
      closeEvent() {
        this.closeModal()
      }
    }
  }
</script>

<style lang="scss">
.shops {

 &__modalButtonCenter {
   justify-content: center;
 }
}
</style>