import Vue from 'vue/dist/vue.js';
const EventBus = new Vue();
export default EventBus;

/*

Dostępne globalne eventy
SET_STEP - ustawienie krok
SET_SALON_ID - ustawianie ID salonu
SET_FULL_STEPS - ustawienie ilości kroków: "full: true" - 5 "full: false" - 3
SET_TYPE_SPACE - ustawienie typu miejsca: "salon" - 0 / "online" - 1
SET_TYPE_SERVICE - ustawienie typu usługi: "jazda" - 0 / "dobor" - 1

*/