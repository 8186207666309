<template>
  <div>
    <div class="shops__sorts" v-show="!isLoading">
      <div class="shops__selectWrapper">
        <div class="">
          {{translate.distance}}
        </div>

        <select class="shops__select" v-model="near">
          <option :value="index" v-for="(item, index) in nears">{{item}}</option>
        </select>
      </div>
    </div>

    <loader :is-loading="isLoading"/>

    <div class="shops__list" v-if="list.length && !isLoading">
      <div class="row retailer__shop__data" v-for="item in list">
        <div class="col-md-3 retailer__shop__data--col">
          <h2 class="shopsResults__row--shop-name">
            <a :href="item.url" class="shops__itemTitle">{{item.title}}</a>
          </h2>
        </div>
        <div class="col-md-3 retailer__shop__data--col">
          <img :src="item.logo" class="retailer__shop__data--logo">
        </div>
        <div class="col-md-3 retailer__shop__data--col">
          <h3 class="retailer__shop__data--title">{{translate.address}}:</h3>
          <div class="retailer__shop__data--address">
            <p class="">
              {{item.address}}
            </p>
            <a :href="getAddress(item.google_address)" class="shops__button shops__button--auto shops__button--mt0">
              {{translate.how_to_get}}
            </a>
          </div>
        </div>
        <div class="col-md-3 retailer__shop__data--col">
          <h3 class="retailer__shop__data--title">{{translate.open_hours}}:</h3>
          <table class="table table-borderless retailer__shop__data--hours">
            <tbody>
            <tr v-for="oh in item.open_hours">
              <td colspan="3">{{oh.day}}.</td>
              <td colspan="3">{{oh.hour}}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from 'axios';

  export default {
    props: {
      translate: {
        required: true
      },
      shopsLink: {
        required: true
      },
      coordinates: {
        required: true
      },
      city: {
        required: false
      },
    },
    components: {},
    watch: {
      near: function (newValue) {
        this.getItems();
      },
      coordinates: {
        handler(val, oldVal){
          this.getItems();
        },
        deep: true
      },
    },
    data: () => ({
      list: [],
      isLoading: false,
      near: 10,
      nears: {
        10: '10km',
        30: '30km',
        45: '45km',
        60: '60km',
        100: '100km',
        200: '200km',
        500: '500km',
      },
    }),
    mounted() {},
    methods: {
      getParams() {
        let param = "?lat=" + this.coordinates.lat + '&lng=' + this.coordinates.lng;

        if (this.city) {
          this.near = 10;
          param = param + '&city=' + this.city;
        }

        param = param + "&distance=" + this.near;

        this.deleteCity();
        return param;
      },
      getAddress(address) {
        return 'http://maps.google.com/maps?daddr=' + address;
      },
      deleteCity() {
        this.$emit('delete-city', true);
      },
      getItems() {
        this.isLoading = true;

        axios.get(this.shopsLink + this.getParams())
          .then((response) => {
            this.list = response.data;
            this.isLoading = false;
          });
      }
    }
  }
</script>
