import Vue from 'vue/dist/vue.js';
import EventBus from './../event-bus';

export default Vue.component('visit-close', {
  props: [],
  data() {
    return {};
  },
  mounted() {},
  methods: {
    close: function() {
      EventBus.$emit('SET_FULL_STEPS', true);
      EventBus.$emit('SET_STEP', 1);
    }
  }
});
