import { gsap } from "gsap";
// import { nodeName } from "jquery";

export function loader() {

  const loader = document.getElementById('loader');
  const loader_logo = document.getElementById('loader_logo');
  const cube_red = document.getElementById('cube_red');
  const cube_gray = document.getElementById('cube_gray');
  const cube_yellow = document.getElementById('cube_yellow');
  const loader_text = document.getElementById('loader_text');

  var tl = gsap.timeline();

  function showCookie(name) {
    if (document.cookie !== "") {
      const cookies = document.cookie.split(/; */);

      for (let i=0; i<cookies.length; i++) {
        const cookiesPart = cookies[i].split("=");
        const cookieName = cookiesPart[0];
        const cookieVal = cookiesPart[1];
        if (cookieName === decodeURIComponent(name)) {
          return decodeURIComponent(cookieVal);
        }
      }
    }
    return false;
  }

  if(!showCookie('logoData')) { 
    document.cookie = "logoData=logoData";

    tl.set(loader_logo, {
      visibility: "visible",
    })

    tl.to(loader_logo, {opacity: 1, duration: .5, delay: 1})
      .addLabel('cubes')
      .from(cube_red, {
        x:"+=116", 
        duration: 1,
        delay: 1,
      }, 'cubes')
      .fromTo(cube_yellow, {
        x:"-=58", 
        duration: 1,
      },{
        x:"+=111", 
        duration: 1,
      }, 'cubes')
      .to(cube_yellow, {
        x:"-=53", 
        duration: 1,
        delay: 1,
      }, 'cubes')
      .from(cube_gray, {
        x:"+=117", 
        duration: 1,
      }, 'cubes')
      .to(loader_text, {opacity: 1, duration: .7})
      .to(loader_logo, {opacity: 0, left: 0, top: 0, duration: .5, delay: .7})
      .to(loader, {opacity:0, duration: .5}, "-=.5" )
      .to(loader, {display: 'none'});
  } else {
    tl.to(loader, {display: 'none', duration: 0, delay: 0});
  }
}