import Vue from 'vue/dist/vue.js';
import EventBus from './../event-bus';
import Calendar from 'v-calendar/lib/components/calendar.umd'

Vue.component('calendar', Calendar);

export default Vue.component('visit-four-step', {
  props: [
    'salon',
    'current_step',
    'type_service',
    'type_space',
  ],
  components: {
    Calendar
  },
  data() {
    return {
      attrs: [
        {
          highlight: 'orange',
          dates: []
        },
      ],
      time_index: null,
      data_index: null,
      dates: [],
      times: [],
      selected_visit: null
    };
  },
  created: function () {
    let dates = [];
    let datesFilters = [];

    this.salon.visits.forEach((item) => {
      if (parseInt(item.visit_type_space) == parseInt(this.type_space) &&
          parseInt(item.visit_type_service) == parseInt(this.type_service)) {
        
        let year = item.visit_date.split('-')[0];
        let month = item.visit_date.split('-')[1] - 1;
        let date = item.visit_date.split('-')[2];
  
        dates.push(new Date(year, month, date));
        datesFilters.push(item);
      }
    });

    this.dates = datesFilters;
    this.attrs[0].dates = dates;
  },
  mounted() {},
  methods: {
    setNextStep: function() {
      let step = this.current_step;
      EventBus.$emit('SET_STEP', step+1);
    },
    dayclick: function(event) {
      this.selected_visit = null;
      this.time_index = null;
      
      document.querySelector('.vc-day').removeClass('active');
  
      document.querySelectorAll('.vc-day').removeClass('active')
      if(document.querySelector(`.id-${event.id}`)) document.querySelector(`.id-${event.id}`).addClass('active');

      this.times = this.dates.filter((item) => {
        return item.visit_date == event.id
      });
    },
    selectVisit: function(visit, key) {
      this.selected_visit = visit;
      this.time_index = key;
      
      EventBus.$emit('SET_VISIT', visit.visit_uuid);
    }
  }
});
