import fontawesome from '@fortawesome/fontawesome';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fas, faOtter } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
fontawesome.library.add(far, fab, fas);

import { navInit } from './scripts/navigation';
import { verticalScrollActiveNavInit } from './scripts/verticalScrollActiveInit';
import { benefitAnimation } from './scripts/benefitAnimation';
import { salonAdvisersAnimation } from './scripts/salonAdvisersAnimation';
import { loader } from './scripts/loader';

import { slickInit } from './scripts/slickInit';
import { sectionFaqInit } from './scripts/sectionFaq';
import { blogInit } from './scripts/blog';
import { mapOnSingle } from './scripts/mapOnSingle';

import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
gsap.registerPlugin(ScrollToPlugin);

//rejestracja funkcji blur dla GSAP
(function() {
  const blurProperty = gsap.utils.checkPrefix("filter"),
        blurExp = /blur\((.+)?px\)/,
        getBlurMatch = target => (gsap.getProperty(target, blurProperty) || "").match(blurExp) || [];

  gsap.registerPlugin({
    name: "blur",
    get(target) {
      return +(getBlurMatch(target)[1]) || 0;
    },
    init(target, endValue) {
      let data = this,
      filter = gsap.getProperty(target, blurProperty),
      endBlur = "blur(" + endValue + "px)",
      match = getBlurMatch(target)[0],
      index;
      if (filter === "none") {
        filter = "";
      }
      if (match) {
        index = filter.indexOf(match);
        endValue = filter.substr(0, index) + endBlur + filter.substr(index + match.length);
      } else {
        endValue = filter + endBlur;
        filter += filter ? " blur(0px)" : "blur(0px)";
      }
      data.target = target; 
      data.interp = gsap.utils.interpolate(filter, endValue); 
    },
    render(progress, data) {
      data.target.style[blurProperty] = data.interp(progress);
    }
  });
})();

let vh = window.innerHeight * 0.01;
// Then we set the value in the --vh custom property to the root of the document
document.documentElement.style.setProperty('--vh', `${vh}px`);

// We listen to the resize event
window.addEventListener('resize', () => {
  // We execute the same script as before
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
});


navInit();
if(document.querySelector('.homsiteVerticalScrollActive')) {
  verticalScrollActiveNavInit();
}

if(document.getElementById('blog_wrapper')) {
  blogInit();
}

if(document.getElementById('map')) {
  mapOnSingle();
}

slickInit();

if(document.getElementById('pytania')) {
  sectionFaqInit();
}

if(document.querySelector('.homesiteSectionBenefits')) {
  benefitAnimation();
}

if(document.querySelector('.salon_our_advisers')) {
  salonAdvisersAnimation();
}

let logoData = sessionStorage.getItem('logoData');

loader();


jQuery(document).ready(function ( $ ) {
  const urlParams = new URLSearchParams(window.location.search);
  if(urlParams.get('salon_id')){
    $('html').addClass('over-hidden');
    $('[data-app-window]').addClass('active');
  }
  // APP
    $('[data-app-open]').click(function() {
      $('html').addClass('over-hidden');
      $('[data-app-window]').addClass('active');
    });
    $('[data-app-close]').click(function() {
      $('html').removeClass('over-hidden');
      $('[data-app-window]').removeClass('active');
    });
  // END APP
  if(location.hash) {
    console.log(location.hash)

    setTimeout(()=>{
      if(gsap.to(window, {duration: .5, scrollTo:{y: $(location.hash), offsetY: 100}})) {
        console.log('run forest. run')
      }
    },1000)
  }

  
  // smooth scroll to ID
  $('.home .menu-item a[href*="#"]').on('click', function (e) {
    e.preventDefault();
    if(window.screen.width >= 1200) {
      gsap.to(window, {duration: .5, scrollTo:{y: $(this).attr('href'), offsetY: 100}});
    } else {
      gsap.to(window, {duration: .5, scrollTo:{y: $(this).attr('href'), offsetY: 80}});
    }
  });

  $('.homsiteVerticalScrollActive__navItem a[href*="#"]').on('click', function (e) {
    e.preventDefault();
    gsap.to(window, 0, {duration: .5, scrollTo:{y: $(this).attr('href'), offsetY: 130}});
  });

  $('.homsiteVerticalScrollActiveMobile__header').on('click', function (e) {
    e.preventDefault();
    gsap.to(window, 0, {duration: .3, scrollTo:{y: $(this), offsetY: 80}});
  });
});

window.FontAwesomeConfig = {
  searchPseudoElements: true
}

import App from './scripts/App/Visit.js';
import Map from './scripts/Map/App.js';

class Core {

  constructor()
  {
    new App();
    new Map();
  }

}

new Core();