import $ from 'jquery';
window.$ = window.jQuery = $;
import 'slick-carousel';

export function slickInit() {
  jQuery(document).ready(function ($) {
    if($(".homesiteSectionBenefits__wrapper") && window.innerWidth < 600) {
      $(".homesiteSectionBenefits__wrapper").slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
        arrows: false,
        infinite: true,
      });
    }
  });

  jQuery(document).ready(function ($) {
    if($(".salon_banner__slider")) {
      $(".salon_banner__slider").slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: false,
        arrows: true,
        infinite: true,
        responsive: [
          {
            breakpoint: 950,
            settings: {
              dots: true,
              arrows: false,
              slidesToShow: 1,
              slidesToScroll: 1,
            }
          }
        ]
      });
    }
  });

  jQuery(document).ready(function ($) {
    if($(".homesiteSectionBlog__blogLoop")) {
      $(".homesiteSectionBlog__blogLoop").slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: false,
        arrows: true,
        infinite: true,
        centerMode: true,
        variableWidth: true,
        responsive: [
          {
            breakpoint: 600,
            settings: {
              dots: true,
              arrows: false,
            }
          }
        ]
      });
    }
  });
  
  if($( window ).width() > 950 ) {
    $('.salon_banner__slider').on('init', function(event, slick){
      let stHeight = $('.salon_banner__textBox').outerHeight();     
      $('.salon_banner__slider .salon_banner__slide').css('height', stHeight + 'px' );
    });
  }
}
