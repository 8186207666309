import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export const benefitAnimation = () => {
  window.addEventListener('DOMContentLoaded', () => {
    const mediaBoxes = document.querySelectorAll('.homesiteSectionBenefits__medium');

    mediaBoxes.forEach(element => {
      const boxes = element.querySelectorAll('.colorBlock');
      gsap.fromTo(boxes, {y: '+=300', opacity: 0, blur: 10}, {y: 0, opacity: 1, blur: 0, stagger: 0.2, duration: 1, ease: 'easeInOut', scrollTrigger: {
        trigger: element,
        start: 'top bottom',
        scrub: true,
      }});  
    })
  });
}