var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "shops" }, [
      _c(
        "div",
        { staticClass: "shops__wrapper" },
        [
          _c("map-search", {
            attrs: {
              "shops-link": _vm.shopsLink,
              translate: _vm.translate,
              "basic-zoom": _vm.zoom,
              "show-search": _vm.showSearch,
            },
            on: { "change-location": _vm.changeLocation },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }