import Vue from 'vue/dist/vue.js';
import classes from './classes';
import forEach from './forEach';
import VisitApp from './Components/VisitApp';

export default class Visit {
  constructor() {
    if (!this.setVars()) return;
  }

  setVars() {
    this.vue = document.querySelector('.visitAppWrapper');
    if (!this.vue) return;
    this.initVue();

    return true;
  }

  initVue() {
    Vue.config.productionTip = true;
    new Vue({
      el: '.visitAppWrapper',
      components: {
        VisitApp
      },
    });
  }
}