import Vue from 'vue/dist/vue.js';
import EventBus from './../event-bus';

export default Vue.component('visit-two-step', {
  props: [
    'current_step',
    'salon'
  ],
  data() {
    return {};
  },
  mounted() {},
  methods: {
    setSalon: function() {
      EventBus.$emit('SET_TYPE_SPACE', 0);
      this.nextStep();
    },
    setOnline: function() {
      EventBus.$emit('SET_FULL_STEPS', false);
      EventBus.$emit('SET_TYPE_SPACE', 1);
      this.nextStep();
    },
    nextStep: function() {
      let step = this.current_step;
      EventBus.$emit('SET_STEP', step+1);
    }
  }
});
