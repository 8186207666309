import Vue from 'vue/dist/vue.js';
import EventBus from './../event-bus';

export default Vue.component('visit-first-step', {
  props: [
    'current_step',
    'default_salon'
  ],
  data() {
    return {
      salon: "0"
    };
  },
  mounted() {
    this.salon = this.default_salon ? this.default_salon : "0";
    this.$nextTick(() => {
      EventBus.$emit('SET_SALON_ID', this.salon)
    });
  },
  methods: {
    nextStep: function() {
      let step = this.current_step;
      EventBus.$emit('SET_STEP', step+1);
    },
    setSalon: function() {
      EventBus.$emit('SET_SALON_ID', this.salon);
    }
  }
});
