import Vue from 'vue/dist/vue.js';
import * as VueGoogleMaps from 'vue2-google-maps'
import VueGeolocation from 'vue-browser-geolocation';

Vue.directive('click-outside', {
  bind () {
    this.event = event => this.vm.$emit(this.expression, event)
    this.el.addEventListener('click', this.stopProp)
    document.body.addEventListener('click', this.event)
  },
  unbind() {
    this.el.removeEventListener('click', this.stopProp)
    document.body.removeEventListener('click', this.event)
  },
  
  stopProp(event) { event.stopPropagation() }
});

export default class AppMap {
  constructor() {
    if (!this.setVars()) return;
  }
  
  setVars() {
    this.vue = document.querySelector('#aaamap');
    if (!this.vue) return;
    this.initVue();
    
    return true;
  }
  
  initVue() {
    Vue.config.productionTip = true;
    
    Vue.component('shops-map', require('./Components/ShopsMap/ShopsMap.vue').default);
    Vue.use(VueGeolocation);
    Vue.use(VueGoogleMaps, {
      load: {
        key: 'AIzaSyBAdVaOQyWqXOmmGAlL9uSajlB3xs3atKc',
        v: '3.26',
        libraries: 'places',
      },
    })
    
    new Vue({
      el: '#aaamap',
    });
  }
}
