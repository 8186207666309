export const sectionFaqInit = () => {
  const questions = document.querySelectorAll('.homesiteSectionFaq__question');

  questions.forEach(element => {
    element.addEventListener('click', ()=>{
      if(element.parentElement.classList.contains('active')) {
        element.parentElement.classList.remove('active');
        
       } else {
        removeClassFromElement('.homesiteSectionFaq__faqBlock', 'active');
        element.parentElement.classList.add('active');
        window.setTimeout(
          ()=>{
            if(window.innerWidth < 1000) {
              window.scrollTo({top: element.getBoundingClientRect().top + document.documentElement.scrollTop - 100, behavior: 'smooth'});
            } else {
              window.scrollTo({top: element.getBoundingClientRect().top + document.documentElement.scrollTop - 120, behavior: 'smooth'});
            }
          }, 250)
       }
    })
  })
}

const removeClassFromElement = (elementClass, classToRemove) => {
  const elements = document.querySelectorAll(elementClass);
  elements.forEach(element=>{
    element.classList.remove(classToRemove);
  })
}