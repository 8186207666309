import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export const verticalScrollActiveNavInit = () => {
  window.addEventListener('DOMContentLoaded', () => {
    const mainWrapper = document.querySelector('.homsiteVerticalScrollActive__wrapper');
    const navWrapper = document.querySelector('.homsiteVerticalScrollActive__navWrapper');
    const mediaSingle = document.querySelectorAll('.homsiteVerticalScrollActive__single');
    const mediaBoxes = document.querySelectorAll('.homsiteVerticalScrollActive__medium');
      
    gsap.to(navWrapper, {scrollTrigger: {
      trigger: mainWrapper,
      pin: navWrapper,
      start: 'top 100',
      end: 'bottom 540',
    }});  
      
    mediaBoxes.forEach(element => {
      const boxes = element.querySelectorAll('.colorBlock');
      gsap.fromTo(boxes, {y: '+=300', opacity: 0, blur: 10}, {y: 0, opacity: 1, blur: 0, stagger: 0.2, duration: 1, ease: 'easeInOut', scrollTrigger: {
        trigger: element,
        start: 'top bottom',
        scrub: true,
      }});  
    })

    const changePoint = (element) => {
      const id = element.getAttribute('id');
      document.querySelectorAll(`.homsiteVerticalScrollActive__navItem a`).forEach((item) => {
        item.parentElement.classList.remove('active');
      })
      document.querySelector(`.homsiteVerticalScrollActive__navItem a[href="#${id}"]`).parentElement.classList.add('active');
    }

    mediaSingle.forEach(element => {
      gsap.to(element, {scrollTrigger: {
        trigger: element,
        start: 'top 400px',
        end: 'bottom 400px',
        onEnter: () => {
          changePoint(element);
        },
        onEnterBack: () => {
          changePoint(element);
        }
      }});  
    })
  });

  const headers = document.querySelectorAll('.homsiteVerticalScrollActiveMobile__header');

  const removeActive = (classToRemove) => {
    headers.forEach(header => {
      header.classList.remove(classToRemove);
      header.querySelector('span').classList.remove(classToRemove);
    })
    document.querySelectorAll('.homsiteVerticalScrollActiveMobile__content').forEach(element => {
      element.classList.remove(classToRemove);
    })
  }
  
  headers.forEach(header => {
    header.addEventListener('click', () => {
      if(!header.classList.contains('active')) {
        const address = header.getAttribute('data-run');
        removeActive('active');
        header.classList.add('active');
        header.querySelector('span').classList.add('active');
        document.getElementById(address).classList.add('active');
        // console.log(document.getElementById(address))
      } else {
        removeActive('active');
      }
    })
  })
}