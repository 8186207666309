import Vue from 'vue/dist/vue.js';
import EventBus from './../event-bus';
import Vuelidate from 'vuelidate';
Vue.use(Vuelidate);
import { required } from 'vuelidate/lib/validators';
import axios from "axios";

export default Vue.component('visit-three-step', {
  props: [
    'salon_id',
    'salon',
    'type_service',
    'type_space',
    'visit',
    'current_step',
    'url_set_visit_post',
    'url_get_form_visit_post',
  ],
  data() {
    return {
      form_send: false,
      is_mobile: false,
      form: {},
      file: null,
      file_name: null,
      allAgreements: 0,
    }
  },
  mounted() {
    this.getForm();
  },
  methods: {
    getForm: function() {
      window.innerWidth <= 768 ? this.is_mobile = true : this.is_mobile = false;
  
      window.addEventListener('resize', () => {
        window.innerWidth <= 768 ? this.is_mobile = true : this.is_mobile = false;
      });
      
      axios.post(this.url_get_form_visit_post, {
        client_type: 2
      }).then( (response) => {
        this.form = response.data;
      }).catch(function (error) {
      });
    },
    setService: function(type) {
      EventBus.$emit('SET_TYPE_SERVICE', type);
      this.setNextStep();
    },
    setNextStep: function() {
      let step = this.current_step;
      EventBus.$emit('SET_STEP', step + 1);
    },
    selectAgreements: function() {
      let loop = Object.keys(this.form.agreements).length;
      this.allAgreements = !this.allAgreements;
  
      for(let i = 0; i < loop; i++) {
        this.form.agreements[`agreement_${i}`].value = this.allAgreements;
      }
    },
    setAgreement: function(key) {
      this.form.agreements[key].value = !this.form.agreements[key].value;
    },
    setLabelCheckbox: function(label) {
      if (label.search("regulation label:") != -1) {
        const shortcodeStart = label.indexOf('[regulation');
        const params = [];
        let shortCode = label.substr(shortcodeStart);
        const shortcodeEnd = shortCode.indexOf(']');
        let shortCodeWithBracket = label.substr(shortcodeStart, shortcodeEnd + 1);
        shortCode = shortCodeWithBracket
          .substring(1, shortCode.length-1)
          .replace('regulation ','')
          .replace('[','')
          .replace(']','');
    
        const labelShortCode = shortCode.split(':');
        for (var i = 0; i < labelShortCode.length; i++) params[labelShortCode[0]] = labelShortCode[1];
    
        return label.replace(shortCodeWithBracket, `<a href="${this.salon.regulation}" target="_blank">${params.label}</a>`);
      }
    
      return label;
    },
    checkShowing(agreement) {
      switch(agreement.showing) {
        case 'is_file':
          return this.file != null;
        case 'always':
          return true;
        default:
          return false;
      }
    },
    onSubmit() {
      this.form_send = true;
      const formData = new FormData();
  
      formData.append('salon', JSON.stringify(this.salon));
      formData.append('salon_id', this.salon_id);
      formData.append('visit', JSON.stringify(this.visit));
      formData.append('form', JSON.stringify(this.form));
      formData.append('file', this.file);
  
      axios({
        method: 'post',
        url: this.url_set_visit_post,
        data: formData,
        headers: {'Content-Type': 'multipart/form-data' }
      })
        .then( (response) => {
          this.form = response.data.data;
          this.form_send = false;
  
          if (response.data.message == 'success') {
            EventBus.$emit('SET_STEP', 6);
          }
        })
        .catch(function (response) {
          console.log('error');
          console.log(response);
        });
    }
  },
  setFile (e) {
    this.file = e.target.files[0];
    this.file_name = this.file.name;
  }
});
