import Vue from 'vue/dist/vue.js';
import EventBus from './../event-bus';
import axios from "axios";

export default Vue.component('visit-five-step', {
  props: [
    'salon',
    'type_service',
    'type_space',
    'default_client',
    'visit',
    'current_step',
    'url_set_visit_post',
    'url_get_form_visit_post',
  ],
  data() {
    return {
      form_send: false,
      client_type: this.default_client,
      files: [],
      files_name: [],
      success: null,
      is_mobile: false,
      form: null,
      allAgreements: 0
    }
  },
  mounted() {
    this.getForm();
  },
  methods: {
    nextStep: function() {
      let step = this.current_step;
      EventBus.$emit('SET_STEP', step+1);
    },
    getForm: function () {
      window.innerWidth <= 768 ? this.is_mobile = true : this.is_mobile = false;
  
      window.addEventListener('resize', () => {
        window.innerWidth <= 768 ? this.is_mobile = true : this.is_mobile = false;
      });
      
      axios.post(this.url_get_form_visit_post, {
        client_type: this.client_type
      }).then( (response) => {
        this.form = response.data;
      }).catch(function (error) {
        console.log('error');
        console.log(error);
      });
    },
    setLabelCheckbox: function(label) {
      if (label.search("regulation label:") != -1) {
        const shortcodeStart = label.indexOf('[regulation');
        const params = [];
        let shortCode = label.substr(shortcodeStart);
        const shortcodeEnd = shortCode.indexOf(']');
        let shortCodeWithBracket = label.substr(shortcodeStart, shortcodeEnd + 1);
        shortCode = shortCodeWithBracket
          .substring(1, shortCode.length-1)
          .replace('regulation ','')
          .replace('[','')
          .replace(']','');
        
        const labelShortCode = shortCode.split(':');
        for (var i = 0; i < labelShortCode.length; i++) params[labelShortCode[0]] = labelShortCode[1];
        
        return label.replace(shortCodeWithBracket, `<a href="${this.salon.regulation}" target="_blank">${params.label}</a>`);
      }
    
      return label;
    },
    selectAgreements: function() {
      let loop = Object.keys(this.form.agreements).length;
      this.allAgreements = !this.allAgreements;
    
      for(let i = 0; i < loop; i++) {
        this.form.agreements[`agreement_${i}`].value = this.allAgreements;
      }
    },
    setAgreement: function(key) {
      this.form.agreements[key].value = !this.form.agreements[key].value;
    },
    checkShowing(agreement) {
      switch(agreement.showing) {
        case 'is_file':
          return this.files.length;
        case 'always':
          return true;
        default:
          return false;
      }
    },
    onSubmit: function() {
      this.form_send = true;
      const formData = new FormData();
      
      formData.append('salon', JSON.stringify(this.salon));
      formData.append('visit', JSON.stringify(this.visit));
      formData.append('form', JSON.stringify(this.form));
      
      this.files.forEach((file, i) => {
        formData.append('file-' + i, file);
      });

      axios({
        method: 'post',
        url: this.url_set_visit_post,
        data: formData,
        headers: {'Content-Type': 'multipart/form-data' }
      })
        .then( (response) => {
          this.form = response.data.data;
          this.form_send = false;
  
          if (response.data.message == 'success') {
            EventBus.$emit('SET_STEP', 7);
          }
        })
        .catch(function (response) {
          console.log('error');
          console.log(response);
        });
    },
    setClientType: function (type) {
      this.client_type = type;
      this.getForm();
    },
    setFile (e) {
      Array.from(e.target.files).forEach(file => {
        if(file.size > (1024 * 1024 * 30)) alert("Maksymalny rozmiar pliku to 30MB");
        else this.files.push(file);
      })
      if(this.files.length === 0) return;

      let size = 0;
      this.files.forEach(file => {
        console.log('file', file.size)
        size+=file.size;
      });

      console.log('size', size)
      if(size > (1024 * 1024 * 30)){
        alert("Maksymalny rozmiar plików to 30MB");
        this.files.pop();
      }
    },
    removeFile(index) {
      this.$delete(this.files, index);
    }
  }
});
