var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.isLoading,
              expression: "!isLoading",
            },
          ],
          staticClass: "shops__sorts",
        },
        [
          _c("div", { staticClass: "shops__selectWrapper" }, [
            _c("div", {}, [
              _vm._v(
                "\n        " + _vm._s(_vm.translate.distance) + "\n      "
              ),
            ]),
            _vm._v(" "),
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.near,
                    expression: "near",
                  },
                ],
                staticClass: "shops__select",
                on: {
                  change: function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.near = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                },
              },
              _vm._l(_vm.nears, function (item, index) {
                return _c("option", { domProps: { value: index } }, [
                  _vm._v(_vm._s(item)),
                ])
              }),
              0
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("loader", { attrs: { "is-loading": _vm.isLoading } }),
      _vm._v(" "),
      _vm.list.length && !_vm.isLoading
        ? _c(
            "div",
            { staticClass: "shops__list" },
            _vm._l(_vm.list, function (item) {
              return _c("div", { staticClass: "row retailer__shop__data" }, [
                _c(
                  "div",
                  { staticClass: "col-md-3 retailer__shop__data--col" },
                  [
                    _c("h2", { staticClass: "shopsResults__row--shop-name" }, [
                      _c(
                        "a",
                        {
                          staticClass: "shops__itemTitle",
                          attrs: { href: item.url },
                        },
                        [_vm._v(_vm._s(item.title))]
                      ),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-md-3 retailer__shop__data--col" },
                  [
                    _c("img", {
                      staticClass: "retailer__shop__data--logo",
                      attrs: { src: item.logo },
                    }),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-md-3 retailer__shop__data--col" },
                  [
                    _c("h3", { staticClass: "retailer__shop__data--title" }, [
                      _vm._v(_vm._s(_vm.translate.address) + ":"),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "retailer__shop__data--address" },
                      [
                        _c("p", {}, [
                          _vm._v(
                            "\n            " +
                              _vm._s(item.address) +
                              "\n          "
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            staticClass:
                              "shops__button shops__button--auto shops__button--mt0",
                            attrs: {
                              href: _vm.getAddress(item.google_address),
                            },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.translate.how_to_get) +
                                "\n          "
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-md-3 retailer__shop__data--col" },
                  [
                    _c("h3", { staticClass: "retailer__shop__data--title" }, [
                      _vm._v(_vm._s(_vm.translate.open_hours) + ":"),
                    ]),
                    _vm._v(" "),
                    _c(
                      "table",
                      {
                        staticClass:
                          "table table-borderless retailer__shop__data--hours",
                      },
                      [
                        _c(
                          "tbody",
                          _vm._l(item.open_hours, function (oh) {
                            return _c("tr", [
                              _c("td", { attrs: { colspan: "3" } }, [
                                _vm._v(_vm._s(oh.day) + "."),
                              ]),
                              _vm._v(" "),
                              _c("td", { attrs: { colspan: "3" } }, [
                                _vm._v(_vm._s(oh.hour)),
                              ]),
                            ])
                          }),
                          0
                        ),
                      ]
                    ),
                  ]
                ),
              ])
            }),
            0
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }