<template>
  <div>
    <div class="shops">
      <div class="shops__wrapper" >
        <map-search :shops-link="shopsLink"
                    :translate="translate"
                    :basic-zoom="zoom"
                    :show-search="showSearch"
                    @change-location="changeLocation"
        />

<!--        <near-list :shops-link="shopsLink"-->
<!--                   :translate="translate"-->
<!--                   :coordinates="coordinates"-->
<!--                   :city="city"-->
<!--                   v-if="showSearch"-->
<!--                   @delete-city="deleteCity"-->
<!--        />-->

      </div>
    </div>
  </div>
</template>
<script>
  import NearList from "./NearList.vue";
  import MapSearch from "./MapSearch.vue";

  export default {
    props: {
      translate: {
        required: true
      },
      zoom: {
        required: true
      },
      showSearch: {
        required: true
      },
      shopsLink: {
        required: true
      }
    },
    components: {
      NearList,
      MapSearch,
    },
    data: () => ({
      coordinates: {},
      city: null,

    }),
    mounted() {
      this.getNears();
    },
    methods: {
      changeLocation(data) {
        this.city = data.city;
        this.coordinates = {
          lat: data.lat,
          lng: data.lng
        }
      },
      deleteCity() {
        this.city = null;
      },
      getNears() {
        this.$getLocation({
          enableHighAccuracy: false,
          timeout: Infinity,
          maximumAge: 0
        }).then(coordinates => {
          this.coordinates = {
            lat: coordinates.lat,
            lng: coordinates.lng
          }
        });
      },
    }
  }
</script>