import Vue from 'vue/dist/vue.js';
import EventBus from './../event-bus';

export default Vue.component('visit-thanks-step', {
  props: [
    'current_step',
    'type_service'
  ],
  data() {
    return {};
  },
  mounted() {},
  methods: {}
});
