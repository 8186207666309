import Vue from 'vue/dist/vue.js';
import EventBus from './../event-bus';

export default Vue.component('visit-steps', {
  props: [
    'current_step'
  ],
  data() {
    return {
      full: true
    };
  },
  mounted() {
    EventBus.$on('SET_FULL_STEPS', (payLoad) => {
      this.full = payLoad;
    });
  },
  methods: {
    setStep: function(step) {
      EventBus.$emit('SET_STEP', step);
      EventBus.$emit('SET_FULL_STEPS', true);
    }
  }
});
